<template>
  <t-split-screen centered>
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <h1>{{ $t('pages.forgot_password.heading') }}</h1>
      <p class="text--grey">
        {{ $t('pages.forgot_password.lead') }}
      </p>

      <t-form class="mt-5" @submit.native.prevent="handleSubmit(submit)">
        <t-validation-wrapper vid="email" :name="$t('fields.email_address')" rules="required|email">
          <t-input
            v-model="email"
            native-type="text"
            :placeholder="`${$t('fields.email_address')}...`"
          >
            <t-icon slot="right" :icon="['far', 'envelope']" />
          </t-input>
        </t-validation-wrapper>

        <t-form-element style="display: flex; justify-content: space-between; align-items: center">
          <t-button-loading native-type="submit" :loading="loading" :disabled="invalid">
            {{ $t('pages.forgot_password.actions.request_reset') }}
          </t-button-loading>
          <nuxt-link class="ml-4" :to="{ name: $RouteEnum.LOGIN }">
            {{ $t('pages.forgot_password.actions.back_to_login') }}
          </nuxt-link>
        </t-form-element>
      </t-form>
    </validation-observer>

    <dashboard-image slot="secondary" name="forgot-password" />
  </t-split-screen>
</template>

<script>
import Vue from 'vue';
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import DashboardImage from '~/components/DashboardImage.vue';

export default Vue.extend({
  components: {
    TValidationWrapper,
    DashboardImage,
  },

  layout: 'standalone',
  middleware: 'guest',

  data: () => ({
    email: null,
    loading: false,
  }),

  methods: {
    async submit() {
      this.loading = true;

      try {
        await this.$auth.forgotPassword({ email: this.email });
        this.$notify.success(this.$t('notifications.password.reset_link_email_sent'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
